<section class="section subscribe-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <div class="subscribe-content text-center">
                    <h2>Subscribe to get updates</h2>
                    <p class="my-4">By subscribing you will get newsleter, promotions adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                    <!-- Subscribe Form -->
                    <form class="subscribe-form">
                        <div class="form-group">
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your email">
                        </div>
                        <button type="submit" class="btn btn-lg btn-block">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>