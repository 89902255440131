<section id="features" class="section features-area style-two overflow-hidden ptb_50">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Tentang ONINDONESIA</h2>
                    <p class="d-none d-sm-block mt-4">ONINDONESIA hadir dengan berbagai fitur unik yang dapat mempermudah transaksi online. 
                        Kami bergerak dibidang pengembangan aplikasi yang berfokus pada penggunaan teknologi, kami menyediakan beberapa layanan aplikasi seperti marketplace, 
                        fulfillment, e-payment dan delivery pada satu aplikasi.
                    <a href="/about-us" style="color: #1254A0;">Selengkapnya <i class="fas fa-angle-double-right"></i></a>
                    </p>
                </div>
            </div>
        </div>
        <!-- ------ -->
        <div class="row ptb_50">
            <div class="col-12 col-md-6 col-lg-3 res-margin">
                <!-- Image Box -->
                <div class="image-box text-center icon-1 wow fadeInLeft" style="padding: 2rem!important;" data-wow-delay="0.4s">
                    <!-- Featured Image -->
                    <div class="featured-img mb-3">
                        <img class="avatar-lg" style="margin: 15px;" src="assets/img/superpartner.png" alt="">
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3><a href="#">ONAPPS</a></h3>
                        <hr>
                        <p>Super Apps yang dapat menjangkau semua aplikasi kebutuhan kamu dengan cepat.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <!-- Image Box -->
                <div class="image-box text-center icon-1 wow fadeInRight" style="padding: 2rem!important;" data-wow-delay="0.4s">
                    <!-- Featured Image -->
                    <div class="featured-img mb-3">
                        <img class="avatar-lg" style="margin: 15px;" src="assets/img/onmarket.png" alt="">
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3><a href="#">ONMARKET</a></h3>
                        <hr>
                        <p>Layanan belanja kebutuhan keseharianmu dengan berbagai promo menarik.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <!-- Image Box -->
                <div class="image-box text-center icon-1 wow fadeInRight" style="padding: 2rem!important;" data-wow-delay="0.4s">
                    <!-- Featured Image -->
                    <div class="featured-img mb-3">
                        <img class="avatar-lg" style="margin: 15px;" src="assets/img/onpay.png" alt="">
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3><a href="#">ONPAYMENT</a></h3>
                        <hr>
                        <p>Layanan pembayaran cashless dengan mudah dan cepat dengan berbagai promo.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 res-margin">
                <!-- Image Box -->
                <div class="image-box text-center icon-1 wow fadeInUp" style="padding: 2rem!important;" data-wow-delay="0.2s">
                    <!-- Featured Image -->
                    <div class="featured-img mb-3">
                        <img class="avatar-lg" style="margin: 15px;" src="assets/img/ondeliv.png" alt="">
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3><a href="https://ondelivery.id/">ONDELIVERY</a></h3>
                        <hr>
                        <p>On Delivery menjangkau pengiriman paket dimanapun kamu berada dengan cepat.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>