<section class="section service-area bg-gray overflow-hidden ptb_100">
    <div class="container">
        <div class="row left-content-between">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <p>Mengapa ONINDONESIA?</p><br>
                <!-- Service Text -->
                <div class="service-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize mb-4">Satu Aplikasi untuk semua kegiatanmu</h2>
                    <!-- Service List -->
                    <ul class="service-list">
                        <!-- Single Service -->
                        <li class="single-service media py-2" style="color: #1254A0;">
                            <div class="service-icon pr-3">
                                <i class="fas fa-check"></i>
                            </div>
                            <div class="service-text media-body">
                                <p>Dengan satu aplikasi super App dapat memberikan akses keseluruh aplikasi yang kamu butuhkan</p>
                            </div>
                        </li>
                        <li class="single-service media py-2" style="color: #1254A0;">
                            <div class="service-icon pr-3">
                                <i class="fas fa-check"></i>
                            </div>
                            <div class="service-text media-body">
                                <p>Memberikan layanan aplikasi yang berguna dan effisien.</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2" style="color: #1254A0;">
                            <div class="service-icon pr-3">
                                <i class="fas fa-check"></i>
                            </div>
                            <div class="service-text media-body">
                                <p>Banyak promo aplikasi menarik </p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2" style="color: #1254A0;">
                            <div class="service-icon pr-3">
                                <i class="fas fa-check"></i>
                            </div>
                            <div class="service-text media-body">
                                <p>Aplikasi yang mudah dimengerti dan digunakan</p>
                            </div>
                        </li>
                    </ul>
                    <a href="/about-us" class="btn mt-4" style="padding: 14px 18px;">Selengkapnya <i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
            <div class="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block">
                <!-- Service Thumb -->
                <div class="service-thumb" style="width: 150%;">
                    <img src="assets/img/onapps-opening-page.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>