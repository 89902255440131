<header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
    <div class="container position-relative">
        <a class="navbar-brand" href="index.html">
            <img class="navbar-brand-regular" style="width: 200px;" src="assets/img/logo-onindonesia-white.png" alt="brand-logo">
            <img class="navbar-brand-sticky" style="width: 200px;" src="assets/img/logo-onindonesia.png" alt="sticky brand-logo">
        </a>
        <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-inner">
            <!--  Mobile Menu Toggler -->
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <nav style="padding-right: 100px;">
                <ul class="navbar-nav" id="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" href="/about-us">TENTANG KAMI</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="javascript:;" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            INFORMASI
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                                <a class="dropdown-item" href="#">FAQ</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="#">BERITA DAN PROMO</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="#">KARIR</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">SYARAT & KETENTUAN</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/privacy-policy">KEBIJAKAN PRIVASI</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/partnership">PARTNER</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/contact">KONTAK</a>
                    </li>
                </ul>
                <div class="example-button-row corner-btn">
                    <button mat-raised-button class="mx-1 btn btn-lg" [matMenuTriggerFor]="menu">
                        <span class="pr-2"><img style="height: 13px !important;" src="assets/img/idn.png"></span>IDN
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <span class="pr-2">
                                <img style="height: 13px !important;" src="assets/img/eng.png">
                            </span>
                                ENG
                        </button>
                        <button mat-menu-item>
                            <span class="pr-2">
                                <img style="height: 13px !important;" src="assets/img/cn.png">
                            </span>
                                中文
                        </button>
                    </mat-menu>
                </div>
            </nav>
        </div>
    </div>
</header>
<router-outlet></router-outlet>