<section id="features" class="section features-area overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Why Small Business Owner's Loves sApp</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Features Item -->
                <ul class="features-item">
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.3s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_1.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Fully functional</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.6s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_2.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Live Chat</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.9s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_3.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Secure Data</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-lg-4 d-none d-lg-block">
                <!-- Featutes Thumb -->
                <div class="features-thumb text-center">
                    <img src="assets/img/features_thumb.png" alt="">
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Features Item -->
                <ul class="features-item">
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.3s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_4.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Easy to Customize</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.6s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_5.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Responsive Design</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.9s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_6.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Help Documentation</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>