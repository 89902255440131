<section class="section breadcrumb-area bg-overlay d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- Breamcrumb Content -->
                <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                    <h2 class="text-white text-capitalize">Berita dan Promo</h2>
                    <ol class="breadcrumb justify-content-center">
                        <li class="breadcrumb-item"><a class="text-uppercase text-white" href="index.html">Home</a></li>
                        <li class="breadcrumb-item text-white active">News and Promos</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="blog">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <section id="blog" class="section blog-area ptb_100">
            <div class="container">
                <div class="row"> 
                    <div class="col-12 col-lg-3">
                        <aside class="sidebar">
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Post Widget -->
                                <div class="accordions widget post-widget" id="post-accordion">
                                    <div class="single-accordion">
                                        <h5>
                                            <a role="button" class="collapse show text-uppercase d-block p-3" data-toggle="collapse" href="#accordion2">Popular Post
                                            </a>
                                        </h5>
                                        <!-- Post Widget Content -->
                                        <div id="accordion2" class="accordion-content widget-content collapse show" data-parent="#post-accordion">
                                            <!-- Post Widget Items -->
                                            <ul class="widget-items" *ngFor="let news of news">
                                                <li>
                                                    <a href="#" class="single-post media p-3">
                                                        <!-- Post Thumb -->
                                                        <div class="post-thumb avatar-md">
                                                            <img class="align-self-center" src="assets/img/avatarr.jpg" alt="">
                                                        </div>
                                                        <div class="post-content media-body pl-3">
                                                            <p class="post-date mb-2">{{news.date}}</p>
                                                            <h5>{{news.title}}</h5>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                    <div class="col-12 col-lg-9">
                        <div class="row">
                            <div class="col-12 col-md-6" *ngFor="let news of news">
                                <!-- Single Blog -->
                                <div class="single-blog res-margin">
                                    <!-- Blog Thumb -->
                                    <div class="blog-thumb">
                                        <a href="#"><img src="assets/img/tumbnaill.jpg" alt=""></a>
                                    </div>
                                    <!-- Blog Content -->
                                    <div class="blog-content p-4">
                                        <!-- Meta Info -->
                                        <ul class="meta-info d-flex justify-content-between">
                                            <li>Category : <a href="#">{{news.category}}</a></li>
                                            <li><a href="#">{{news.date}}</a></li>
                                        </ul>
                                        <!-- Blog Title -->
                                        <h3 class="blog-title my-3"><a href="#">{{news.title}}</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-paginator 
                                    [length]="100"
                                    [pageSize]="10"
                                    [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-footer-one></app-footer-one>
    </div>
</div>