<section class="branding-area    ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Partner ONINDONESIA</h2>
                    <p class="d-none d-sm-block mt-4">Bergabunglah dengan ONINDONESIA sebagai partner. ONINDONESIA berkomitmen memberikan segala kebutuhan dalam satu aplikasi. Hubungi kami untuk informasi lebih lanjut.</p>
                    <br><br>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="branding-slider owl-carousel ">
                <div class="single-brand p-3">
                    <img src="assets/img/logo-amazon.png" alt="">
                </div>
                <div class="single-brand p-3">
                    <img src="assets/img/logo-bukalapak.png" alt="">
                </div>
                <div class="single-brand p-3">
                    <img src="assets/img/logo-shopee.png" alt="">
                </div>
                <div class="single-brand p-3">
                    <img src="assets/img/logo-tokopedia.png" alt="">
                </div>
                <div class="single-brand p-3">
                    <img src="assets/img/logo-klik-indomaret.png" alt="">
                </div>
            </div>
        </div> -->
    </div>
</section>