<section class="section team-area team-style-two overflow-hidden ptb_50">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Our Team Experts</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <!-- Single Team -->
                <div class="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                    <!-- Team Thumb -->
                    <div class="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                        <img src="assets/img/avatar-1.png" alt="">
                        <!-- Team Overlay -->
                        <div class="team-overlay radius-100">
                            <h4 class="team-name text-white">Junaid Hasan</h4>
                            <h5 class="team-post text-white mt-2 mb-3">Founder &amp; CEO</h5>
                            <!-- Team Icons -->
                            <div class="team-icons">
                                <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-google-plus-g"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <!-- Single Team -->
                <div class="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                    <!-- Team Thumb -->
                    <div class="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                        <img src="assets/img/avatar-2.png" alt="">
                        <!-- Team Overlay -->
                        <div class="team-overlay radius-100">
                            <h4 class="team-name text-white">Jassica William</h4>
                            <h5 class="team-post text-white mt-2 mb-3">Co-Founder</h5>
                            <!-- Team Icons -->
                            <div class="team-icons">
                                <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-google-plus-g"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <!-- Single Team -->
                <div class="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                    <!-- Team Thumb -->
                    <div class="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                        <img src="assets/img/avatar-3.png" alt="">
                        <!-- Team Overlay -->
                        <div class="team-overlay radius-100">
                            <h4 class="team-name text-white">Md. Arham</h4>
                            <h5 class="team-post text-white mt-2 mb-3">Web Developer</h5>
                            <!-- Team Icons -->
                            <div class="team-icons">
                                <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-google-plus-g"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <!-- Single Team -->
                <div class="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                    <!-- Team Thumb -->
                    <div class="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                        <img src="assets/img/avatar-4.png" alt="">
                        <!-- Team Overlay -->
                        <div class="team-overlay radius-100">
                            <h4 class="team-name text-white">Yasmin Akter</h4>
                            <h5 class="team-post text-white mt-2 mb-3">Graphic Designer</h5>
                            <!-- Team Icons -->
                            <div class="team-icons">
                                <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-google-plus-g"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>