<div class="homepage-4">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-welcome-four></app-welcome-four>
        <app-service-four></app-service-four>
        <app-discover-four></app-discover-four>
        <app-feature-four></app-feature-four>
        <app-work></app-work>
        <app-screenshot-two></app-screenshot-two>
        <app-review-two></app-review-two>
        <app-pricing-one></app-pricing-one>
        <app-faq></app-faq>
        <app-download></app-download>
        <app-subscribe></app-subscribe>
        <app-contact></app-contact>
        <app-footer-one></app-footer-one>
    </div>
</div>