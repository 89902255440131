<div class="d-none d-lg-block"></div>
<footer class="footer-area height-emulator text-white">
    <!-- Footer Top -->
    <div class="footer-top pt-2 pb-5">
        <div class="container mt-5">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-3 mb-5">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title mb-3">INFORMASI</h3>
                        <ul>
                            <li class="py-1"><a href="/about-us">Profil Perusahaan </a></li>
                            <li class="py-1"><a href="#">FAQ</a></li>
                            <li class="py-1"><a href="#">Berita dan Promo</a></li>
                            <li class="py-1"><a href="#">KARIR</a></li>

                            <li class="pt-5 pb-1"><a href="#">Terms &amp; Conditions</a></li>
                            <li class="py-1"><a href="/privacy-policy">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title mb-3">LAYANAN</h3>
                        <ul>
                            <li class="py-1"><a href="#">On Apps</a></li>
                            <li class="py-1"><a href="#">On Market</a></li>
                            <li class="py-1"><a href="#">On Payment</a></li>
                            <li class="py-1"><a href="https://ondelivery.id/">On Delivery</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <ul class="service-list">
                        <!-- Single Service -->
                        <div class="footer-items ">
                            <h3 class="footer-title mb-3">INFO PERUSAHAAN</h3>
                            <li class="single-service media py-2">
                                <div class="icon pr-3">
                                    <span class="material-icons">location_city</span>
                                </div>
                                <div class="service-text media-body">
                                    <p>Menara Satu Sentra Kelapa Gading</p>
                                    <p>Jl. Boulevard Raya RT. 011/018</p>
                                    <p>Kelapa Gading, Jakarta Utara </p>
                                    <p>Kode Pos ID 14240</p>
                                </div>
                            </li>
                            <li class="single-service media py-2">
                                <div class="icon pr-3">
                                    <span class="material-icons">local_phone</span>
                                </div>
                                <div class="service-text media-body">
                                    <p>(021) 50555007</p>
                                </div>
                            </li>
                            <li class="single-service media py-2">
                                <div class="icon pr-3">
                                    <span class="material-icons">email</span>
                                </div>
                                <div class="service-text media-body">
                                    <a href="mailto:marketing@onindonesia.id">marketing@onindonesia.id</a>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 mb-4 pl-5">
                    <h3 class="footer-title">STAY CONNECTED</h3>
                    <!-- Social Icons -->
                    <div class="social-icons d-flex mb-3">
                        <a href="https://www.instagram.com/onindonesia.id/" target="_blank">
                            <i class="fab fa-instagram fa-lg"></i>
                        </a>
                        <a href="#">
                            <i class="fab fa-twitter fa-lg"></i>
                        </a>
                        <a href="#">
                            <i class="fab fa-facebook fa-lg"></i>
                        </a>
                        <a href="#">
                            <i class="fab fa-youtube fa-lg"></i>
                        </a>
                    </div>
                    <p style="font-weight: bold;">DOWNLOAD APP</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons store-black d-flex flex-wrap mt-3">
                        <a href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps" target="_blank">
                            <img src="assets/img/google-play.png" alt="">
                        </a>
                        <!-- <a href="#">
                            <img src="assets/img/app-store.png" alt="">
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left">&copy; Copyrights 2021 ONINDONESIA. All rights reserved.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>