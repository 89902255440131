import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

news : any

  constructor(){


    this.news=[
      {
        "category":"Promo",
        "title":"Promo On Merdeka",
        "date":"17 Agustus 2021",
        "content":"promo menarik pada tanggal 17 Agustus"
      },{
        "category":"Promo",
        "title":"Promo Hari Kasih Sayang",
        "date":"14 Februari 2021",
        "content":"Nikmati promo menyenangkan dengan orang tersayang"
      },{
        "category":"News",
        "title":"On Delivery Resmi Beroperasi",
        "date":"30 Maret 2021",
        "content":"tes"
      },{
        "category":"News",
        "title":"Berbagi Kebaikan dengan ONINDONESIA",
        "date":"18 Januari 2021",
        "content":"Turut berbagi kebahagiaan dengan korban bencana alam."
      },
    ]
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.paginator = this.paginator;
  }

}
