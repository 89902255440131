<section class="section service-area overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <!-- Service Text -->
                <div class="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                    <h2 class="text-capitalize mb-4">Kenali ONINDONESIA lebih dekat</h2>
                    <div class="service-text">
                        <p>Memberikan layanan aplikasi yang optimal. Kami memanfaatkan teknologi perangkat lunak untuk membuat sebuah aplikasi yang berguna.
                        </p>
                    </div><br>
                    <!-- Service List -->
                    <ul class="service-list style-two">
                        <!-- Single Service -->
                        <li class="single-service py-2">
                            <div class="service-text">
                                <p>Fitur delivery untuk pengiriman paket kerumah Anda dengan cepat.
                                </p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service py-2">
                            <div class="service-text">
                                <p>Fitur Market untuk pembelian barang dimana saja.</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service py-2">
                            <div class="service-text">
                                <p>Fitur partner untuk menggunakan fitur aplikasi partner kami</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                <!-- Service Thumb -->
                <div class="service-thumb mx-auto">
                    <img src="assets/img/coba.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>