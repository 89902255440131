<section id="features" class="section bg-gray features-area overflow-hidden mt-5 ptb_50">
    <div class="container">

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="features-slider-wrapper overflow-hidden">
                    <!-- Work Slider -->
                    <ul>
                        <li class="slide-item">
                            <img src="assets/img/tes.jpg" alt="">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 pt-4 pt-md-0">
                <!-- Section Heading -->
                <div class="service-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize mb-4">Unduh ONINDONESIA sekarang</h2>
                </div>
                <ul class="features-item">
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media icon-1 px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.2s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <span class="material-icons">insert_emoticon</span>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Mudah Digunakan</h3>
                                <p>ONINDONESIA bersifat user friendly dan dapat diunduh di Play Store dan App Store</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media icon-2 px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.4s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <span class="material-icons">widgets</span>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Fitur Menarik</h3>
                                <p>Fitur yang bermanfaat dan menarik dalam satu aplikasi</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media icon-3 px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.6s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <span class="material-icons">mobile_friendly</span>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Banyak Promo</h3>
                                <p>Tersedia berbagai promo menarik yang dapat digunakan</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="button-group store-buttons d-flex">
                    <a href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps" target="_blank">
                        <img src="assets/img/google-play-black.png" alt="">
                    </a>
                    <!-- <a href="#">
                        <img src="assets/img/app-store-black.png" alt="">
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</section>