<section class="section breadcrumb-area bg-overlay d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- Breamcrumb Content -->
                <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                    <h2 class="text-white text-capitalize">Tentang ONINDONESIA</h2>
                    <ol class="breadcrumb justify-content-center">
                        <li class="breadcrumb-item"><a class="text-uppercase text-white" href="index.html">Home</a></li>
                        <li class="breadcrumb-item text-white active">About Us</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</section> 
<div class="contact-page">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <section class="section service-area overflow-hidden ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6 order-2 order-lg-1">
                        <!-- Service Text -->
                        <div class="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                            <h2 class="text-capitalize mb-4">Kenali ONINDONESIA lebih dekat</h2>
                            <div class="service-text">
                                <p>ONINDONESIA hadir dan memberikan layanan aplikasi Super App yang optimal, kami memanfaatkan teknologi perangkat lunak untuk membuat sebuah aplikasi yang berguna. 
                                    ONINDONESIA memberikan beberapa jenis layanan yang bisa Anda gunakan.
                                    
                                </p>
                            </div><br>
                            <!-- Service List -->
                            <ul class="service-list style-two">
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>On Delivery sebagai layanan jasa antar barang yang dapat Anda percaya untuk mengirim barang sampai ke tujuan.</p>
                                    </div>
                                </li>
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>On Market dapat diandalkan sebagai platform untuk melakukan promosi bisnis Anda atau Anda dapat mencari barang yang dibutuhkan dengan harga yang terjangkau.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                        <!-- Service Thumb -->
                        <div class="service-thumb mx-auto">
                            <img src="assets/img/onapps.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-why-us></app-why-us>
        <section class="section discover-area overflow-hidden ptb_100">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <!-- Discover Text -->
                        <div class="discover-text pt-4 pt-lg-0 px-0 px-lg-4">
                            <h2 class="pb-4">ONINDONESIA Untuk Masyarakat</h2>
                            <!-- Service List -->
                            <ul class="service-list style-two">
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Kami hadir untuk memberikan kemudahan bagi setiap pengguna. Dengan menerapkan bantuan teknologi dan karyawan yang berpengalaman, kami hadir untuk Indonesia.</p>
                                    </div>
                                </li>
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Kami memberikan peluang bisnis bagi pelaku usaha mikro dan makro dengan menggunakan aplikasi on market.</p>
                                    </div>
                                </li>
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Saat ini kami beroperasi di Jakarta dan secara bertahap akan terus mengembangkan sayap untuk memberikan kemudahan layanan kami pada masyarakat Indonesia.</p>
                                    </div>
                                </li>
                                <!-- Single Service -->
                                <li class="single-service py-2">
                                    <div class="service-text">
                                        <p>Unduh sekarang juga ONINDONESIA secara gratis di Apple Store dan Play Store di perangkat Anda.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-footer-one></app-footer-one>
    </div>
</div>