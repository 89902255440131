<section class="section work-area bg-overlay overflow-hidden ptb_50">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4">
                <!-- Single Work -->
                <div class="single-work text-center p-3">
                    <!-- Work Icon -->
                    <div class="work-icon"> 
                        <i class="avatar-md material-icons">get_app</i>
                    </div>
                    <h3 class="text-white py-3">Pasang Aplikasi</h3>
                    <p class="text-white">Unduh aplikasi ONINDONESIA pada perangkatmu, saat ini aplikasi kami sudah tersedia di Play Store</p>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <!-- Single Work -->
                <div class="single-work text-center p-3">
                    <!-- Work Icon -->
                    <div class="work-icon"> 
                        <i class="avatar-md material-icons">manage_accounts</i>
                    </div>
                    <h3 class="text-white py-3">Atur Profilmu</h3>
                    <p class="text-white">Daftar dan atur Profilmu pada aplikasi. Lengkapi semua informasi pribadi yang diperlukan di aplikasi</p>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <!-- Single Work -->
                <div class="single-work text-center p-3">
                    <!-- Work Icon -->
                    <div class="work-icon"> 
                        <i class="avatar-md material-icons">widgets</i>
                    </div>
                    <h3 class="text-white py-3">Nikmati Fiturnya</h3>
                    <p class="text-white">Ya, aplikasimu siap digunakan. Nikmati berbagai fitur yang kami sediakan dalam satu aplikasi</p>
                </div>
            </div>
        </div>
    </div>
</section>