<section class="section faq-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Have questions? Look here</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <!-- sApp Accordion -->
                    <div class="accordion" id="sApp-accordion">
                        <div class="row justify-content-center">
                            <div class="col-12 col-md-10 col-lg-8">
                                <!-- Single Accordion Item -->
                                <div class="card border-top-0 border-left-0 border-right-0 border-bottom">
                                    <!-- Card Header -->
                                    <div class="card-header bg-inherit border-0 p-0">
                                        <h2 class="mb-0">
                                            <button class="btn px-0 py-3" type="button" data-toggle="collapse" data-target="#collapseOne">
                                                How to install sApp?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" class="collapse show" data-parent="#sApp-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body px-0 py-3">
                                            The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card border-top-0 border-left-0 border-right-0 border-bottom">
                                    <!-- Card Header -->
                                    <div class="card-header bg-inherit border-0 p-0">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed px-0 py-3" type="button" data-toggle="collapse" data-target="#collapseTwo">
                                                Can I get support from the Author?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" data-parent="#sApp-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body px-0 py-3">
                                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card border-top-0 border-left-0 border-right-0 border-bottom">
                                    <!-- Card Header -->
                                    <div class="card-header bg-inherit border-0 p-0">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed px-0 py-3" type="button" data-toggle="collapse" data-target="#collapseThree">
                                                Do you have a free trail?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" data-parent="#sApp-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body px-0 py-3">
                                            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card border-top-0 border-left-0 border-right-0 border-bottom">
                                    <!-- Card Header -->
                                    <div class="card-header bg-inherit border-0 p-0">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed px-0 py-3" type="button" data-toggle="collapse" data-target="#collapseFour">
                                                How can I edit my personal information?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" data-parent="#sApp-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body px-0 py-3">
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card border-top-0 border-left-0 border-right-0 border-bottom">
                                    <!-- Card Header -->
                                    <div class="card-header bg-inherit border-0 p-0">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed px-0 py-3" type="button" data-toggle="collapse" data-target="#collapseFive">
                                                Contact form isn't working?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse" data-parent="#sApp-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body px-0 py-3">
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>