<div class="contact-page">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-breadcrumb-contact></app-breadcrumb-contact>
        <section id="contact" class="contact-area bg-gray ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8">
                        <!-- Section Heading -->
                        <div class="section-heading">
                            <h2>Kontak ONINDONESIA</h2>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-12 col-md-5">
                        <!-- Contact Us -->
                        <div class="contact-us">
                            <p class="mb-3 mt-3">Kontak kami melalui media berikut</p>
                            <ul>
                                <li class="py-2 mb-2">
                                    <a class="media">
                                        <div class="social-icon mr-3">
                                            <i class="fas fa-building"></i>
                                        </div>
                                        <span class="media-body align-self-center">
                                            <p class=" mb-2">Menara Satu Sentra Kelapa Gading</p>
                                            <p>Jl. Boulevard Raya RT. 011/018</p>
                                            <p>Kelapa Gading, Jakarta Utara </p>
                                            <p>Kode Pos ID 14240</p>
                                        </span>
                                    </a>
                                </li>
                                <li class="py-2 mb-2">
                                    <a class="media">
                                        <div class="social-icon mr-3">
                                            <i class="fas fa-phone-alt"></i>
                                        </div>
                                        <span class="media-body align-self-center">(021) 50555007 </span>
                                    </a>
                                </li>
                                <li class="py-2 mb-2">
                                    <a class="media" href="#">
                                        <div class="social-icon mr-3">
                                            <i class="fas fa-envelope"></i>
                                        </div>
                                        <span class="media-body align-self-center"><a href="mailto:marketing@onindonesia.id">marketing@onindonesia.id</a></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <h4 class="mt-5 mb-3 font-weight-bold">Kontak Layanan Pengaduan Konsumen</h4>
                        <p class="mb-3">Direktorat Jenderal Perlindungan Konsumen dan Tertib Negara Kementerian Perdagangan Republik Indonesia</p>
                        <div class="contact-us">
                            <ul>
                                <!-- <li class="py-2 mb-2">
                                    <a class="media">
                                        <div class="social-icon mr-3">
                                            <i class="fas fa-phone-alt"></i>
                                        </div>
                                        <span class="media-body align-self-center">+62-21-3858171<span class="ml-4">+62-21-3451692</span></span>
                                        
                                    </a>
                                </li> -->
                                <li>
                                    <a class="media">
                                        <!-- <div class="social-icon mr-3">
                                            <i class="fab fa-whatsapp"></i>
                                        </div> -->
                                        <span class="media-body align-self-center">WA : 085311111010</span>
                                    </a>
                                </li>
                                <!-- <li class="py-2 mb-2">
                                    <a class="media" href="#"> 
                                        <div class="social-icon mr-3">
                                            <i class="fas fa-envelope"></i>
                                        </div>
                                        <span class="media-body align-self-center"><a href="mailto:pengaduan.konsumen@kemendag.go.id">pengaduan.konsumen@kemendag.go.id</a></span>
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 pt-4 pt-md-0">
                        <!-- Contact Box -->
                        <div class="contact-box ">
                            <p class="mb-3 mt-3" style="font-weight: bold;">STAY CONNECTED</p>
                            <div class="social-icons d-flex mb-5">
                                <a href="https://www.instagram.com/onindonesia.id/" target="_blank">
                                    <i class="fab fa-instagram fa-lg"></i>
                                </a>
                                <a href="#">
                                    <i class="fab fa-twitter fa-lg"></i>
                                </a>
                                <a href="#">
                                    <i class="fab fa-facebook fa-lg"></i>
                                </a>
                                <a href="#">
                                    <i class="fab fa-youtube fa-lg"></i>
                                </a>
                            </div>
                            <p class="mt-5" style="font-weight: bold;">DOWNLOAD APP</p>
                            <!-- Store Buttons -->
                            <div class="button-group store-buttons store-black d-flex flex-wrap">
                                <a href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps" target="_blank">
                                    <img src="assets/img/google-play-black.png" alt="">
                                </a>
                                <!-- <a href="#">
                                    <img src="assets/img/app-store-black.png" alt="">
                                </a> -->
                            </div>
                            <p class="form-message"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section map-area">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2101.3302381134454!2d106.90465840094926!3d-6.159767531487372!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe9632ebb1a076abb!2sMenara%20Satu%20Sentra%20Kelapa%20Gading!5e0!3m2!1sen!2sid!4v1614922094006!5m2!1sen!2sid"
                width="100" height="50" style="border: 1px;;" allowfullscreen=""></iframe>
        </section>
        <app-footer-one></app-footer-one>
    </div>
</div>