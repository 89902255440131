<section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-6">
                <div class="welcome-intro">
                    <h1 class="text-white">Anything You Need In One App</h1>
                    <p class="text-white my-4">Semua lebih mudah dalam satu aplikasi</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-flex flex-wrap">
                        <a href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps" target="_blank">
                            <img src="assets/img/google-play.png" alt="">
                        </a>
                        <a href="#">
                            <img src="assets/img/app-store.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-6">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb mx-auto" data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
                    <img src="assets/img/for-homepage.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- Shape Bottom -->
    <div class="shape-bottom">
        <svg viewBox="0 0 1920 310" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="svg replaced-svg">
            <title>sApp Shape</title>
            <desc>Created with Sketch</desc>
            <defs></defs>
            <g id="sApp-Landing-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="sApp-v1.0" transform="translate(0.000000, -554.000000)" fill="#FFFFFF">
                    <path d="M -3 551 C 186.2576 757.3211 243 798 360 811 C 562 815 559 795 637.7317 765.9615 C 713.9383 735.5939 816.9806 681.9106 1035.7221 733.0655 C 1254.4635 784.2204 1399 762 1473 748 C 1680 696 1641 639 1831 636 C 1908 632 1901.7565 588.3577 1934.3228 557.4627 L 1934.3228 863.1834 L -3 863.1834"></path>
                </g>
            </g>
        </svg>
    </div>
</section>