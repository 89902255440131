<div class="main">
    <app-scrollup></app-scrollup>
    <app-header-one></app-header-one>
    <app-welcome-one></app-welcome-one>
    <app-feature-one></app-feature-one>
    <app-work></app-work>
    <app-service-one></app-service-one>
    <app-brand></app-brand>
    <app-feature-four></app-feature-four>
    <app-footer-one></app-footer-one>
</div>