<section id="screenshots" class="section bg-gray screenshots-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Kenapa ONINDONESIA?</h2>
                    <p class="d-none d-sm-block mt-4">Dengan satu aplikasi super App dapat memberi akses ke aplikasi yang kamu butuhkan</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5">
        <div class="row">
            <div class="col-12">
                <!-- App Screenshot Slider Area -->
                <div class="app-screenshots">
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <div class="icon-box text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <!-- Featured Icon -->
                            <div class="featured-icon mb-3">
                                <i class="far fa-thumbs-up fa-3x" style="color:#1792b4;"></i>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text">
                                <h3 class="mb-2">Mempermudah</h3>
                                <p>Aplikasi ONINDONESIA mudah dimengerti dan digunakan</p>
                            </div>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <div class="icon-box text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                            <!-- Featured Icon -->
                            <div class="featured-icon mb-3">
                                <i class="fas fa-mobile-alt fa-3x" style="color:#1792b4;"></i>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text">
                                <h3 class="mb-2">Super Apps</h3>
                                <p>Mempermudah akses aplikasi dalam satu super apps</p>
                            </div>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <div class="icon-box text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                            <!-- Featured Icon -->
                            <div class="featured-icon mb-3">
                                <i class="fas fa-tags fa-3x" style="color:#1792b4;"></i>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text">
                                <h3 class="mb-2">Promo Menarik</h3>
                                <p>Menghadirkan berbagai promo menarik pada aplikasi</p>
                            </div>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <div class="icon-box text-center wow fadeInUp" data-wow-duration="2s">
                            <!-- Featured Icon -->
                            <div class="featured-icon mb-3">
                                <i class="fas fa-mobile-alt fa-3x" style="color:#1792b4;"></i>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text">
                                <h3 class="mb-2">Aman & Terpercaya</h3>
                                <p>seluruh data yang Kamu masukkan aman bersama kami</p>
                            </div>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <div class="icon-box text-center wow fadeInUp" data-wow-duration="2s">
                            <!-- Featured Icon -->
                            <div class="featured-icon mb-3">
                                <i class="fas fa-hand-holding-usd fa-3x" style="color:#1792b4;"></i>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text">
                                <h3 class="mb-2">Harga Terjangkau</h3>
                                <p>Harga pengiriman paket terjangkau</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>