<div class="homepage-3 inner inner-pages">
    <div class="main">
        <app-header-one></app-header-one>
        <section id="home" class="section welcome-area inner-area bg-overlay h-100vh overflow-hidden">
            <div class="container h-100">
                <div class="row align-items-center justify-content-center h-100">
                    <div class="col-12 col-md-7">
                        <div class="welcome-intro text-center">
                            <h1 class="text-white">We are coming soon!</h1>
                            <p class="text-white my-4">Our website is under construction. We'll be here soon with our new awesome site, subscribe to be notified.</p>
                            <div class="countdown-times mb-4">
                                <div class='countdown d-flex justify-content-center' data-date="2021-12-09"></div>
                            </div>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form d-flex align-items-center mx-auto">
                                <input type="email" class="form-control" placeholder="info@yourmail.com">
                                <button type="submit" class="btn btn-bordered">Notify</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>